exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ads-tsx": () => import("./../../../src/pages/ads.tsx" /* webpackChunkName: "component---src-pages-ads-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manage-tsx": () => import("./../../../src/pages/manage.tsx" /* webpackChunkName: "component---src-pages-manage-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-tornado-tsx": () => import("./../../../src/pages/tornado.tsx" /* webpackChunkName: "component---src-pages-tornado-tsx" */),
  "component---src-pages-users-[username]-tsx": () => import("./../../../src/pages/users/[username].tsx" /* webpackChunkName: "component---src-pages-users-[username]-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-workspace-tsx": () => import("./../../../src/pages/workspace.tsx" /* webpackChunkName: "component---src-pages-workspace-tsx" */)
}

